//APPLICATION
.application {

	address {
		margin: 0;
	}

	&__icon, &__logo {
		padding-right:15px;
	}

	&__name a, &__name a:link, &__name a:active, &__name a:visited{
		color: var(--bs-secondary);
		word-wrap: anywhere;
		font-weight: 500;
	}

	&__name a:hover{
		color: var(--bs-primary);
	}

	&__description {
		color: var(--bs-secondary);
		margin-top:1rem;
	}

	&__start_date {
		color: var(--bs-secondary);
	}

	&__state {
		> span {
			color: var(--bs-secondary);
			padding-left: 5px;
		}
	}

	&__category {
		color: var(--bs-secondary);
	}

	&__domain {
		word-break: break-word;
	}

	&__renewal_amount{
		color: var(--bs-secondary);
	}

	&__renewal_date{
		color: var(--bs-secondary);
	}

	&__parent {
		color: var(--bs-secondary);
		padding-top: 0.3em;
		font-weight: 400;
		font-size: 0.9em;
	}

	&__last_payment {
		color: $text-muted;
		font-size: 0.9em;
	}

	&__meta_updated_date {
		font-weight: 400;
		font-size: 0.9em;
		color: $text-muted;
	}

	&__letter-icon {
		background-color: var(--bs-primary);
		position: relative;
		display: inline-block;
		border-radius: 5px;
		color: var(--bs-light);
		text-transform: uppercase;
		text-decoration: none;
		&:before {
			content: attr(first-letter);
			font-weight: bold;
			@include center-middle();
		}
	}

}

#organizationApplicationSearch {
	position: absolute;
	background: var(--bs-white);
	padding: 0.5rem 1rem;
	>ul {
		margin-bottom: 0;
		>li {
			padding-bottom: 0.5rem;
			&:hover {
				background: var(--bs-light);
			}
		}
		>li:last-child {
			padding-bottom: 0;
		}
	}
}


