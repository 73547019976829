//TRHEAD
.thread {
	
	&-body {
		background-color: var(--bs-light);

		img {
			image-rendering: -webkit-optimize-contrast;
			max-width: 100%;
  			height: auto;
		}
	}
}
