.tool-color{display:inline-block;height:1em;width:1em;margin-bottom:-2px;}

.tool-color-default{background-color:$mapcolorDefault;border-color:$mapcolorDefault;}
.tool-color-color01{background-color:$mapcolor01;border-color:$mapcolor01;}
.tool-color-color02{background-color:$mapcolor02;border-color:$mapcolor02;}
.tool-color-color03{background-color:$mapcolor03;border-color:$mapcolor03;}
.tool-color-color04{background-color:$mapcolor04;border-color:$mapcolor04;}
.tool-color-color05{background-color:$mapcolor05;border-color:$mapcolor05;}
.tool-color-color06{background-color:$mapcolor06;border-color:$mapcolor06;}
.tool-color-color07{background-color:$mapcolor07;border-color:$mapcolor07;}
.tool-color-color08{background-color:$mapcolor08;border-color:$mapcolor08;}
.tool-color-color09{background-color:$mapcolor09;border-color:$mapcolor09;}
.tool-color-color10{background-color:$mapcolor10;border-color:$mapcolor10;}
.tool-color-color11{background-color:$mapcolor11;border-color:$mapcolor11;}
.tool-color-color12{background-color:$mapcolor12;border-color:$mapcolor12;}
.tool-color-color13{background-color:$mapcolor13;border-color:$mapcolor13;}
.tool-color-color14{background-color:$mapcolor14;border-color:$mapcolor14;}
.tool-color-color15{background-color:$mapcolor15;border-color:$mapcolor15;}
.tool-color-color16{background-color:$mapcolor16;border-color:$mapcolor16;}
.tool-color-color17{background-color:$mapcolor17;border-color:$mapcolor17;}
.tool-color-white{background-color:$mapcolorWhite;border-color:$mapcolorWhite;box-shadow:0px 0px 1px #000;}

.color-menu{
    box-sizing:border-box;
    display:flex;
    flex-wrap:wrap;
    min-width:200px;
    a{
        min-width:25px;
        margin:5px;
        padding:5px;
        height:2rem;
        width:calc(25% - 20px);
        max-width:calc(25% - 20px);
    }
}

.border-demo{
    border-width:0px;
    display:inline-block;
    width:100%;
    height:100%;
    background:transparent;
}

.border-demo span{
    border-width:4px;
    border-style:solid;
    border-color:inherit;
    display:inline-block;
    width:100%;
    height:100%;
    background:transparent;
}

.color-demo{
    display:inline-block;
    width:100%;
    height:100%;
}

.btn .styletext-size{
    width:18px;
}

.btn .border-demo,.btn .color-demo{
    width:14px;
    height:14px;
    margin-bottom:-2px;
}

.btn .border-demo span{
    width:14px;
    height:14px;
    display:block;
}

.position-menu{
    display:flex;
    align-content:flex-start;
    justify-content:flex-start;
    flex-wrap:wrap;
    padding:0px 10px;
    .dropdown-item{
        flex-grow:1;
        min-width:20px;
        margin:0px;
        padding:5px 0px;
        width:calc(33%);
        text-align:center;
        height:auto;
        line-height:0.5em;
    }
    span.dropdown-item{
        pointer-events:none;
    }
}

.shapetype-menu{
    display:flex;
    align-content:flex-start;
    justify-content:flex-start;
    flex-wrap:wrap;
    padding:0px 10px;
    .dropdown-item{
        flex-grow:1;
        min-width:20px;
        margin:0px;
        padding:5px 0px;
        width:calc(33%);
        text-align:center;
        height:auto;
        line-height:0.5em;
    }
    svg{
        min-width:30px;
    }
    svg *{
        vector-effect:non-scaling-stroke;
    }
}

svg.position-demo{
    margin-bottom:-3px;
    fill:none;
    stroke:#666;
    stroke-width:1px;
}

svg.nocolor{
    rect{fill:none;stroke-width:1;stroke:black;}
    line{stroke-width:1px;stroke:red;}
    vertical-align:top;
}

.btn svg.nocolor{
    width:14px;
    height:14px;
    vertical-align:baseline;
    margin-bottom:-2px;
}

.btn svg.position-demo{
    width:16px;
    height:16px;
    vertical-align:baseline;
}

.dropdown-toggle .styletext-border{
    width:25px;
}

.conflictingValues{
    position:relative;
}

.conflictingValues:before{
    content:"?";
    position:absolute;
    height:100%;
    width:100%;
    top:-1px;
    left:-1px;
    font-size:11px;
    line-height:15px;
    min-height:16px;
    font-weight:bold;
    color:#000;
    text-shadow:0.5px 0.5px 0px #fff;
    background:rgba(255,255,255,0.7);
    box-sizing:content-box;
    border:1px solid rgba(255,255,255,0.7);
    border-radius:0;
}

.alignOptions{
    background-color: var(--bs-light);
    position:absolute;
    flex-direction: column;
    z-index:300;
    padding:10px;
    margin:0;
}

.alignOptions a{
    line-height:5px;
    padding:6px;
}

.alignOptions svg{
    width:21px;
    height:21px;
    fill:none;
    stroke:#666;
    stroke-width:1px;
}

.cs-width-demo{stroke:#262626 !important;fill:none;}
.dropdown-toggle .cs-width-demo svg{width:25px;}
.dropdown-menu .cs-width-demo{width:100%;}
.dropdown-toggle .cs-dash-dashed{stroke-dasharray: 5px 3px;}