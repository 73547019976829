$mapcolorDefault:#1c57a4;
$mapcolor01:#ffde03;
$mapcolor02:#0336ff;
$mapcolor03:#ff0266;
$mapcolor04:#b00020;
$mapcolor05:#03dac5;
$mapcolor06:#6200ee;
$mapcolor07:#3700b3;
$mapcolor08:#ff0266;
$mapcolor09:#9D9D9D;
$mapcolor10:#5C7C89;
$mapcolor11:#BF5151;
$mapcolor12:#6969CC;
$mapcolor13:#66AFAF;
$mapcolor14:#D3C666;
$mapcolor15:#AC51C0;
$mapcolor16:#000000;
$mapcolor17:#00adef;
$mapcolorWhite:#ffffff;

#addApplicationToMapModal .modal-content,#addApplicationToMapModal .modal-body{overflow:visible;}

.cursor-{ /* We're assigning these classes to BODY to change the cursor on the page while resizing/dragging stuff */
    &default{cursor:default !important;*{cursor:default !important}}
    &resize-bottom{cursor:ns-resize !important;*{cursor:ns-resize !important;}}
    &resize-top{cursor:ns-resize !important;*{cursor:ns-resize !important;}}
    &resize-left{cursor:ew-resize !important;*{cursor:ew-resize !important;}}
    &resize-right{cursor:ew-resize !important;*{cursor:ew-resize !important;}}
    &resize-top-left{cursor:nwse-resize !important;*{cursor:nwse-resize !important;}}
    &resize-bottom-right{cursor:nwse-resize !important;*{cursor:nwse-resize !important;}}
    &resize-top-right{cursor:nesw-resize !important;*{cursor:nesw-resize !important;}}
    &resize-bottom-left{cursor:nesw-resize !important;*{cursor:nesw-resize !important;}}
    &draggable{cursor:grab !important;*{cursor:grab !important;}}
    &dragging{cursor:grabbing !important;*{cursor:grabbing !important;}}
}

.appMapSaving,.appMapLoading{ /* The spinner icons in the corner of the blueprin */
    position:absolute;
    top:15px;
    right:15px;
    pointer-events:none;
}

.appContainerScrollable{ /* Outermost wrapper with a scrollbar */
    margin:0 -15px;
    white-space:nowrap;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch;
    background:#f0f4f8;
    box-shadow:inset 0 .125rem .25rem rgba(0,0,0,.075) !important;
    padding:5px;
}

.appContainerResizeable{ /* Middle wrapper with a resize handle */
    position:relative;
    transition:none;
    white-space:nowrap;
    margin:0 auto;
    padding:0;
    width: 100%;
    background-repeat:no-repeat;
    background-position:bottom right;
    background-color:#fff;
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' stroke='rgba(0,0,0,0.2)'><path d='M1 9, 9 1' /><path d='M5 9, 9 5' /></svg>");
}

.appMapResize{ /* Blueprint resize handles */
    position:absolute;
    background:transparent;
    user-select:none;
    &.resizeBottom{bottom:-6px;height:12px;width:100%;cursor:ns-resize;}
    &.resizeLeft{top:0px;left:-6px;height:100%;width:12px;cursor:ew-resize;}
    &.resizeRight{top:0px;right:-6px;height:100%;width:12px;cursor:ew-resize;}
    &.resizeBottomRight{bottom:-6px;right:-6px;height:12px;width:12px;cursor:nwse-resize;}
    &.resizeBottomLeft{bottom:-6px;left:-6px;height:12px;width:12px;cursor:nesw-resize;}
}

.appMapResizeTip{ /* The tooltip that comes up when resizing the blueprint */
    display:block;
    position:absolute;
    padding:0px 5px;
    font-size:12px;
    background:var(--bs-info);
    color:#fff;
    border-radius:2px;
    z-index:999;
}

.appContainer{ /* The blueprint itself, contains applications and other stuff */
    width:100%;
    min-height:100%;
    height:100%;
    position:relative;
    overflow:visible;
    &.appContainerReadonly{ /* Non-interactive appContainer */

    }
    &.appContainerEditable{ /* Interactive app container where you can drag/drop/edit things */
        user-select:none; /* This way dragging doesn't select any text */
        &>*{ /* All the draggable objects */
            cursor:grab;
        }
        &>.dragging{ 
            box-shadow:2px 2px 3px rgba(0,0,0,0.2);
            &.applicationNode,&.mapShape{z-index:100!important;}
            &.mapShape[dragtype='move']{pointer-events:none !important;}
        }
        &>*>img,&>*>span{ /* Making some img/text elements ignore mouse events */
            pointer-events:none !important;
        }
    }
    /* Fading out elements inside of a blueprint */
    .fadeOut{opacity:0.1;pointer-events:none !important;}
    .mapArrow path.path.fadeOut{opacity:0.1;}
    .fadeOut *{pointer-events:none !important;}
}

.appContainerEditable:not(.modeDefault){ /* When blueprint is in any mode other than default we fade out things and disable mouse events */
    cursor:copy;
    .applicationNode,.mapArrow,.connectionDescription,.connectionFaq,.mapArea,.mapShape{
        pointer-events:none !important;
        opacity:0.5;
    }
    .mapArrow path{
        pointer-events:none !important;
    }
}

.appContainerEditable .selectionRect{ /* The selection rectangle */
    position:absolute;
    top:0;
    left:0;
    border:1px solid rgba(28,87,164,0.3);
    background:rgba(28,87,164,0.1);
    cursor:default;
    pointer-events:none !important;
}

.cursorFollow{ /* 1-pixel invisible div that follows cursor. Needed to attach an arrow to, when creating a new integration */
    position:fixed;
    width:1px;
    height:1px;
    pointer-events:none;
}