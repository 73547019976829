.mapArrow{
	pointer-events:none;
	transition:opacity 0.5s;
	stroke:$mapcolorDefault;
	fill:$mapcolorDefault;
	stroke-width:1.5px;
	z-index:1;
	path.pathHover{ /* Transparent line that reacts to hover */
		cursor:pointer;
		opacity:0;
		stroke-width:10px;
		stroke-dasharray:none;
		fill:none;
		pointer-events:stroke;
	}
	path.path{ /* Visible line taht we configure with classes */
		pointer-events:none;
		opacity:1;
	}
}

.connectionDescription{
	position:absolute;
	max-width:300px;
	text-align:center;
	background:rgba(255,255,255,0.8);
	box-shadow: 0px 0px 10px 10px rgba(255,255,255,0.8);
	line-height:1.5em;
	color:$mapcolorDefault;
	z-index:2;
    white-space: pre-wrap;
}

.connectionFaq{
	position:absolute;
	text-align:center;
	background:rgba(255,255,255,0.8);
	line-height:1.5em;
	color:$mapcolorDefault;
	z-index:2;
	width:1.5em;
	height:1.52em;
	border-style:solid;
	border-width:2px;
	border-radius:99px;
	&:after{
		content:"i";
	}
}

.appContainerReadonly{ /* In readonly container (the one that is not interactive and can't be edited) we hide FAQs*/
	.connectionFaq{display:none !important;}
}

.appContainerEditable{ /* Things that only happen in editable container (as opposed to readonly) */
	.mapArrow:hover path.pathHover{opacity:0.5;stroke:#eded00}
	.mapArrow.selected path.pathHover{stroke:#000;opacity:0.1;}
	.mapArrow.selected:hover path.pathHover{stroke:mix(#000,#eded00,50);opacity:0.25;}
	.connectionDescription,.connectionFaq{cursor:pointer;}
}

.cs-{ /* For "Connection Style". All the styles that modify appearance of a connection arrow */
	&width-{
		&1{stroke-width:1.5px;}
		&2{stroke-width:2.5px;}
		&3{stroke-width:3.5px;} 
		&4{stroke-width:4.5px;}
	}
	&color-{
		&default{stroke:$mapcolorDefault;fill:$mapcolorDefault;color:$mapcolorDefault;border-color:$mapcolorDefault;}
		&color01{stroke:$mapcolor01;fill:$mapcolor01;color:$mapcolor01;border-color:$mapcolor01;}
		&color02{stroke:$mapcolor02;fill:$mapcolor02;color:$mapcolor02;border-color:$mapcolor02;}
		&color03{stroke:$mapcolor03;fill:$mapcolor03;color:$mapcolor03;border-color:$mapcolor03;}
		&color04{stroke:$mapcolor04;fill:$mapcolor04;color:$mapcolor04;border-color:$mapcolor04;}
		&color05{stroke:$mapcolor05;fill:$mapcolor05;color:$mapcolor05;border-color:$mapcolor05;}
		&color06{stroke:$mapcolor06;fill:$mapcolor06;color:$mapcolor06;border-color:$mapcolor06;}
		&color07{stroke:$mapcolor07;fill:$mapcolor07;color:$mapcolor07;border-color:$mapcolor07;}
		&color08{stroke:$mapcolor08;fill:$mapcolor08;color:$mapcolor08;border-color:$mapcolor08;}
		&color09{stroke:$mapcolor09;fill:$mapcolor09;color:$mapcolor09;border-color:$mapcolor09;}
		&color10{stroke:$mapcolor10;fill:$mapcolor10;color:$mapcolor10;border-color:$mapcolor10;}
		&color11{stroke:$mapcolor11;fill:$mapcolor11;color:$mapcolor11;border-color:$mapcolor11;}
		&color12{stroke:$mapcolor12;fill:$mapcolor12;color:$mapcolor12;border-color:$mapcolor12;}
		&color13{stroke:$mapcolor13;fill:$mapcolor13;color:$mapcolor13;border-color:$mapcolor13;}
		&color14{stroke:$mapcolor14;fill:$mapcolor14;color:$mapcolor14;border-color:$mapcolor14;}
		&color15{stroke:$mapcolor15;fill:$mapcolor15;color:$mapcolor15;border-color:$mapcolor15;}
		&color16{stroke:$mapcolor16;fill:$mapcolor16;color:$mapcolor16;border-color:$mapcolor16;}
		&color17{stroke:$mapcolor17;fill:$mapcolor17;color:$mapcolor17;border-color:$mapcolor17;}
		&white{stroke:$mapcolorWhite;fill:$mapcolorWhite;color:$mapcolorWhite;border-color:$mapcolorWhite;}
	}
	&dash-{
		&solid{}
		&dashed{
			stroke-dasharray:7px 6px;
			&.cs-width-1{stroke-dasharray:7px 6px;}
			&.cs-width-2{stroke-dasharray:8px 7px;}
			&.cs-width-3{stroke-dasharray:9px 8px;}
			&.cs-width-4{stroke-dasharray:10px 9px;}
		}
		&dotted{
			stroke-dasharray:1.5px 4px;
			&.cs-width-1{stroke-dasharray:1.5px 4px;}
			&.cs-width-2{stroke-dasharray:2.5px 5px;}
			&.cs-width-3{stroke-dasharray:3.5px 5px;}
			&.cs-width-4{stroke-dasharray:4.5px 6px;}
		}
	}
	&size-{
		&xs{font-size:0.8em;}
		&x1{font-size:1.0em;}
		&x2{font-size:1.2em;}
		&x3{font-size:1.4em;}
		&x4{font-size:1.6em;}
	}
}