.flexcontent button {
  display: inline-block;
  border-radius: 0 3px 3px 0;
}
#new-tool-container .messages .alert {
  padding: 0;
  font-size: 14px;
}
#new-tool-container .messages {
  display: none;
}