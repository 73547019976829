#datatable {
	.searchclear {
	    position: absolute;
	    right: 5px;
	    top: 0;
	    bottom: 0;
	    height: 14px;
	    margin: auto;
	    font-size: 14px;
	    cursor: pointer;
	    color: #ccc;
	}
	.searchclearhidden{
	    display:none;
	}
	.editable-empty, .editable-empty:hover, .editable-empty:focus {
		color: var(--bs-secondary);
	}

	a.editable-click {
   	 	text-decoration: none;
    	border-bottom: dashed 1px var(--bs-secondary);
    }

     a.editable-click:hover {
     	text-decoration: bold;
     	color: var(--primary);
    	border-bottom: dashed 1px var(--bs-primary);
    	cursor: pointer;
     }

    .editable-click {
    	border-bottom: dashed 1px var(--bs-secondary);
    }

    .th__fixed {
    	background-color: var(--bs-light);
    }

    th:not(.th__fixed, .applications-table__tfoot__th) {
    	background-color: var(--bs-primary);
    	color: var(--bs-white);
    }

	&__th {
		color: var(--bs-white);
		background-color: var(--bs-primary);
	}

	&__input__filter {
		padding:2px 5px;
		background-color: var(--bs-primary);
		border:var(--bs-light) 1px solid;
	}
		
	&__input__filter:focus {
			border:var(--bs-light) 1px solid;
		}

	&__input__filter::placeholder {
			color:var(--bs-primary);
		}

	&__body-td {
		border-top: none;
		border-bottom: 2px solid var(--bs-light);
		padding:15px 10px;
	}

	&__link-view {
		color: var(--bs-secondary);
		font-weight:600;
	}
}