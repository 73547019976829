html {
  height: -webkit-fill-available;
}

body {
	color: var(--bs-dark);
	font-family:var(--bs-font-family-sans-serif);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  	min-height: 100vh;
  	min-height: -webkit-fill-available;
}

main {
	background-color: var(--bs-white);
  	min-height: 100vh;
}

p {
	word-break: overflow-wrap;
}

a {
	color: var(--bs-primary-dark);
}

dd {
	background: var(--bs-white-ghost);
	border-radius: 5px;
	padding: 0.5em;
	min-height: 2.5em;

	address {
		margin-bottom: 0;
	}
}
    	
.article,.comment,.toastui-editor-contents {
	blockquote {
	  background: var(--bs-white-ghost);
	  border-left: 1rem solid var(--bs-primary-light);
	  margin: 1.5rem 0;
	  padding: 1rem;
	  quotes: "\201C""\201D""\2018""\2019";
	}
	blockquote:before {
	  color: var(--bs-primary);
	  content: open-quote;
	  font-size: 4em;
	  line-height: 0.1em;
	  margin-right: 0.25em;
	  vertical-align: -0.4em;
	}
	blockquote p {
	  display: inline;
	}
}

table {
	td,th {
		vertical-align: middle !important;
	}
}

pre[data-lang]::before { content: attr(data-lang); display: block; }
pre:not(.phpdebugbar-widgets-code-block) { 
	font-size: 1em;
	line-height: 20px;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
	background-color: var(--bs-dark);
	overflow-x: auto;
	tab-width: 4;
	border-radius: 5px;
	padding: 1rem;
	> code {
		white-space: break-spaces;
		font-family: Courier, 'New Courier', monospace;
		color: var(--bs-white) !important;
	}
}

[data-avatar="true"] + span {
	padding-left: .5em;
}

.comment {
	background: var(--bs-white);
	color: var(--bs-secondary);
	padding: .5rem;
	margin: 0 0 .5rem 0 !important;
	width: 100%;
	word-wrap: all;
	overflow: hidden;
	border-radius: 5px;

	p:last-child {
		margin-bottom: 0 !important;
	}
}


[data-initials]:before {
background-color: var(--bs-primary-dark);
color: var(--bs-white);
content: attr(data-initials); 
display: inline-block; 
border-radius: 5px; 
min-width: 100%;
width: 100%;
height: 100%;
text-align: center; 
}

.timeline {
	list-style-type: none;
    position: relative;
}

.timeline-item:not(:last-child) {
	padding-bottom: 1em;
}

.timeline-item:before, .timeline:before {
    content: " ";
    display: inline-block;
    position: absolute;
    z-index: 1;
}

.timeline-item:before {
	background: var(--bs-white);
	border-radius: 50%;
	border: 3px solid var(--bs-muted);
	left: 0;
	width: 20px;
	height: 20px;
}

.timeline:before {
    background: var(--bs-muted);
    left: 9px;
    width: 2px;
    height: 100%;
}