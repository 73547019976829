@use "sass:math";

$ph-bg:#fff !default;
$ph-color:#ced4da !default;
$ph-border-radius:2px !default;
$ph-gutter:30px !default;
$ph-spacer:15px !default;
$ph-avatar-border-radius:50% !default;
$ph-animation-duration:.8s !default;

.ph-item {
  &, *,
  ::after, ::before {
    box-sizing: border-box;
  }

  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: $ph-gutter;
  background-color: $ph-bg;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, .35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ($ph-spacer * 0.5);

  div {
    height: 10px;
    margin-bottom: ($ph-spacer * 0.5);
    background-color: $ph-color;
  }
  .big,&.big div{
    height: 20px;
    margin-bottom: $ph-spacer;
  }
  .bigger,&.bigger div{
    height: 40px;
    margin-bottom: $ph-spacer;
  }
  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

.ph-col-1{flex:0 0 percentage(math.div(1, 12));}
.ph-col-2{flex:0 0 percentage(math.div(2, 12));}
.ph-col-3{flex:0 0 percentage(math.div(3, 12));}
.ph-col-4{flex:0 0 percentage(math.div(4, 12));}
.ph-col-5{flex:0 0 percentage(math.div(5, 12));}
.ph-col-6{flex:0 0 percentage(math.div(6, 12));}
.ph-col-7{flex:0 0 percentage(math.div(7, 12));}
.ph-col-8{flex:0 0 percentage(math.div(8, 12));}
.ph-col-9{flex:0 0 percentage(math.div(9, 12));}
.ph-col-10{flex:0 0 percentage(math.div(10, 12));}
.ph-col-11{flex:0 0 percentage(math.div(11, 12));}
.ph-col-12{flex:0 0 percentage(math.div(12, 12));}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;
  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 80px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
