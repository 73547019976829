.navbar {
	border-bottom: 0;
	padding: 1rem;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .05) !important;
}

.navbar.navbar-expand-md {
	margin-top: -1rem;
	margin-bottom: 1rem;
}

/*
 * Navbar
 */

.navbar-brand {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, .25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
	top: .25rem;
	right: 1rem;
}

.navbar .form-control {
	padding: .75rem 1rem;
	border-width: 0;
	border-radius: 0;
}

.form-control-dark {
	color: var(--bs-white);
	background-color: rgba(255, 255, 255, .1);
	border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
	color: var(--bs-light);
	border-color: transparent;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}