//NOTIFICATIONS
.alert {
	z-index:2;
	position: relative;
	padding: 15px;
	font-size: 1.1em;
	color: var(--white);
	border: none;
	border-radius:0;
	margin-bottom: 0;
	&-info {
		background-color: var(--info);
	}
	&-success {
		background-color: var(--success);
	}
	&-danger {
		background-color: var(--danger);
	}
	&-warning {
		background-color: var(--warning);
	}
	&__inline-link {
		color: var(--white);
		font-weight: 400;
		text-decoration: underline;
		&:hover {
			color: var(--white);
		}
	}
	button.close {
		color: var(--white) !important;
	}
}
