.overlap {
		margin-left:-12px;

		img {
				border: transparent;
		}
}

.blur{
	-webkit-filter: blur(5px); /* Chrome, Safari, Opera */
	filter: blur(5px);
}

.scrollarea {
	overflow-y: auto;
}

.reveal {
		cursor: pointer;
}

.date {
		vertical-align: middle;
		color: var(--bs-secondary);
}

.free {
		color: var(--bs-success);
		font-weight: 600;
}

.free_trial {
		border-radius: 3px;
		background-color: var(--bs-warning);
		color: var(--bs-white);
		padding:3px 10px;
}

.today {
		border-radius: 3px;
		background: var(--bs-success);
		color: var(--bs-white);
		padding:3px 10px;
}

.tomorrow {
		border-radius: 3px;
		background: var(--bs-warning);
		color: var(--bs-white);
		padding:3px 10px;
}

#faqs-view {

	ol > li,ul > li {
		margin-bottom: 0.2rem;
	}
}

#pages {
		
	ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	}

	ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.1rem;
	}

	ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6rem;    
	}

	li ol > li {
		margin: 0;
	}

	li ol > li:before {
		content: counters(item, ".") " ";
	}
}