.mapShape{
    top:0;
    left:0;
    position:absolute;
    fill:mix($mapcolorDefault,#fff,10);
    stroke:$mapcolorDefault;
    color:$mapcolorDefault;
    stroke-width:1px;
    font-size:1em;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:2;
    line-height: 0.85em;
}

.mapShape.dragging{
    opacity:0.6;
}
.mapShape *{
    pointer-events:none;
}
.mapShape .shapePopover{
    pointer-events:initial;
    cursor:pointer;
    &:hover{
        text-decoration:none;
    }
}
.mapShape > svg{
    position:absolute;
    left:0;
    top:0;
    width:100% !important;
    height:100% !important;
    overflow:visible;
}
.mapShape > svg *{
    vector-effect:non-scaling-stroke;
}
.mapShape > div{
    position:relative;
    text-shadow:rgba(0,0,0,0.1) 1px 1px;
    margin:0.1em 0.5em;
}

.mapShape .title{
    font-weight: 600;
    line-height: 1rem;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    white-space: break-spaces;
}

.mapShape .description{
    font-size:0.8em;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    white-space: break-spaces;
}

/* Hides empty title and/or description */
.mapShape .title:empty,.mapShape .description:empty{
   display: none;
}

.appContainerEditable{
    .mapShape.selected{box-shadow:0 0 5px rgba(0,0,0,0.6);will-change:transform;}
}

.ss-{ /* For "Shape Style" */
    /* Padding for some asymetrical shape types */
    &type-{
        &manual > div{padding-top:5%;}
        &manualop > div{padding-bottom:5%;}
        &delay > div{padding-right:15%;}
        &document > div{padding-bottom:7%;}
        &connectordown > div{padding-bottom:5%;}
        &connectorright > div{padding-right:35%;}
        &connectorleft > div{padding-left:35%;}
    }
    &size-{
        &xs{font-size:0.8em;}
        &x1{font-size:1.0em;}
        &x2{font-size:1.2em;}
        &x3{font-size:1.4em;}
        &x4{font-size:1.6em;}
    }
    &fillcolor-{
        &default{fill:mix($mapcolorDefault,#fff,10) !important;}
        &color01{fill:mix($mapcolor01,#fff,10) !important;}
        &color02{fill:mix($mapcolor02,#fff,10) !important;}
        &color03{fill:mix($mapcolor03,#fff,10) !important;}
        &color04{fill:mix($mapcolor04,#fff,10) !important;}
        &color05{fill:mix($mapcolor05,#fff,10) !important;}
        &color06{fill:mix($mapcolor06,#fff,10) !important;}
        &color07{fill:mix($mapcolor07,#fff,10) !important;}
        &color08{fill:mix($mapcolor08,#fff,10) !important;}
        &color09{fill:mix($mapcolor09,#fff,10) !important;}
        &color10{fill:mix($mapcolor10,#fff,10) !important;}
        &color11{fill:mix($mapcolor11,#fff,10) !important;}
        &color12{fill:mix($mapcolor12,#fff,10) !important;}
        &color13{fill:mix($mapcolor13,#fff,10) !important;}
        &color14{fill:mix($mapcolor14,#fff,10) !important;}
        &color15{fill:mix($mapcolor15,#fff,10) !important;}
        &color16{fill:mix($mapcolor16,#fff,10) !important;}
        &color17{fill:mix($mapcolor17,#fff,10) !important;}
        &white{fill:$mapcolorWhite !important;}
        &no{fill:transparent !important;}
    }
    &bordercolor-{
        &default{stroke:rgba($mapcolorDefault,0.7);color:$mapcolorDefault;}
        &color01{stroke:rgba($mapcolor01,0.7);color:$mapcolor01;}
        &color02{stroke:rgba($mapcolor02,0.7);color:$mapcolor02;}
        &color03{stroke:rgba($mapcolor03,0.7);color:$mapcolor03;}
        &color04{stroke:rgba($mapcolor04,0.7);color:$mapcolor04;}
        &color05{stroke:rgba($mapcolor05,0.7);color:$mapcolor05;}
        &color06{stroke:rgba($mapcolor06,0.7);color:$mapcolor06;}
        &color07{stroke:rgba($mapcolor07,0.7);color:$mapcolor07;}
        &color08{stroke:rgba($mapcolor08,0.7);color:$mapcolor08;}
        &color09{stroke:rgba($mapcolor09,0.7);color:$mapcolor09;}
        &color10{stroke:rgba($mapcolor10,0.7);color:$mapcolor10;}
        &color11{stroke:rgba($mapcolor11,0.7);color:$mapcolor11;}
        &color12{stroke:rgba($mapcolor12,0.7);color:$mapcolor12;}
        &color13{stroke:rgba($mapcolor13,0.7);color:$mapcolor13;}
        &color14{stroke:rgba($mapcolor14,0.7);color:$mapcolor14;}
        &color15{stroke:rgba($mapcolor15,0.7);color:$mapcolor15;}
        &color16{stroke:rgba($mapcolor16,0.7);color:$mapcolor16;}
        &color17{stroke:rgba($mapcolor17,0.7);color:$mapcolor17;}
        &white{stroke:rgba($mapcolorWhite,0.7);}
        &no{stroke:transparent;background:transparent;}
    }
    &border-{
        &no{
            stroke-width:0px;
            > svg{padding:0;}
        }
        &1{
            stroke-width:1px;
            > svg{padding:0.5px;}
        }
        &2{
            stroke-width:2px;
            > svg{padding:1px;}
        }
        &3{
            stroke-width:3px;
            > svg{padding:1.5px;}
        }
        &4{
            stroke-width:4px;
            > svg{padding:2px;}
        }
    }
    &position-{
        &topleft{align-items:start;justify-content:left;text-align:left;}
        &topcenter{align-items:start;justify-content:center;text-align:center;}
        &topright{align-items:start;justify-content:flex-end;text-align:right;}

        &middleleft{align-items:center;justify-content:left;text-align:left;}
        &middlecenter{align-items:center;justify-content:center;text-align:center;}
        &middleright{align-items:center;justify-content:flex-end;text-align:right;}

        &bottomleft{align-items:flex-end;justify-content:left;text-align:left;}
        &bottomcenter{align-items:flex-end;justify-content:center;text-align:center;}
        &bottomright{align-items:flex-end;justify-content:flex-end;text-align:right;}

        &topcenterover{
            align-items:start;justify-content:center;text-align:center;
            > div{margin-top:-12px;}
            > div{padding:0px;}
            span{padding:0px 5px;background:rgba(255,255,255,0.8);box-shadow:0px 0px 7px 4px rgba(255,255,255,0.8);}
            &.ss-corners-round > div{margin-top:-17px;}
            &.ss-size-xs > div{margin-top:-10px;}
            &.ss-size-x1 > div{margin-top:-12px;}
            &.ss-size-x2 > div{margin-top:-14px;}
            &.ss-size-x3 > div{margin-top:-16px;}
            &.ss-size-x4 > div{margin-top:-19px;}
            &.ss-fillcolor-no span{background:white !important;box-shadow:none !important;}
        }

        &bottomcenterover{
            align-items:flex-end;justify-content:center;text-align:center;
            > div{margin-bottom:-9px;}
            > div{padding:0px;}
            span{padding:0px 5px;background:rgba(255,255,255,0.8);box-shadow:0px 0px 7px 4px rgba(255,255,255,0.8);}
            &.ss-corners-round > div{margin-bottom:-14px;}
            &.ss-size-xs > div{margin-bottom:-7px;}
            &.ss-size-x1 > div{margin-bottom:-10px;}
            &.ss-size-x2 > div{margin-bottom:-12px;}
            &.ss-size-x3 > div{margin-bottom:-14px;}
            &.ss-size-x4 > div{margin-bottom:-15px;}
            &.ss-fillcolor-no span{background:white !important;box-shadow:none !important;}
        }

        &topcenterabove{
            align-items:start;justify-content:center;text-align:center;
            > div{padding:0px;}
            > div > div{transform:translate(0,-100%);padding-bottom:5px;}
        }

        &bottomcenterbelow{
            align-items:flex-end;justify-content:center;text-align:center;
            > div{padding:0px;}
            > div > div{margin-bottom:-100%;padding-top:5px;}
        }
    }
}