.nav-scroller {
	overflow-x: scroll;
  	overflow-y: hidden;
  	white-space: nowrap;
}

.nav-scroller.nav {
  	flex-wrap: nowrap;
}

/* The following is used in the top nav bar */
.navbar {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
}