/*return to top*/
#return-to-top {
  @media (min-width: 768px) {
    z-index: 9999;
      position: fixed;
      bottom: 0;
      right: 0;
      background: var(--bs-primary);
      color: var(--bs-white);
      width: 40px;
      height: 40px;
      display: block;
      text-decoration: none;
      display: none;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
}
#return-to-top i {
    margin: 0;
    position: absolute;
    left: 0.75em;
    top: 0.75em;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
    color: var(--bs-white);
    top: 5px;
}

/*add clear search input*/
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  cursor: pointer;

  /* Now your own custom styles */
  height: 20px;
  width: 20px;
  background:  var(--bs-primary);
  /* Will place small red box on the right of input (positioning carries over) */
}

//in editable, we still use glyphicon so this sets .fa replacements
.glyphicon {
    @extend .fa;
    // Overloading "glyphicon-chevron-left" with "fa-arrow-left".
    &.glyphicon-ok {
        @extend .fa-check !optional;
    }
    // Overloading "glyphicon-chevron-right" with "fa-arrow-right".
    &.glyphicon-remove {
        @extend .fa-times !optional;
    }
}
.editable-pre-wrapped {white-space: revert !important;}

.small-progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #a6cfff;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden; }
  .small-progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: #3a88e3;
    transition: width .3s linear; }
  .small-progress .indeterminate {
    background-color: #3a88e3; }
    .small-progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .small-progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s; }

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

tfoot {
    display: table-header-group;
}
.dataTables_filter{
    display:none;
}
.date-control {
    font-size: 10px;
    height: 29px;
    width: 48px !important;
    padding: 0;
}
.editable-input {
    display: block;
}
.editable-buttons {
    display: block !important;
    margin-top: 10px;
}
.combodate{
    width: 180px;
    display: block;
}
.editable-buttons button{
    font-size:9px;
}

span.text-red {
    color: #8bc01b;
}

.addApp.addAppLoading{animation:bluePulses 1.5s linear infinite;}
.addApp.addAppSuccess{animation:bumpGreenPulse 0.7s linear 1;}

@keyframes bluePulses{
    0%{box-shadow: 0 0 0 0 rgba(0,135,226,0.7), 0 0 0 0 rgba(0,135,226,0.7);}
    40%{box-shadow: 0 0 0 15px rgba(0,135,226,0), 0 0 0 0 rgba(0,135,226,0.7);}
    80%{box-shadow: 0 0 0 15px rgba(0,135,226,0), 0 0 0 15px rgba(0,135,226,0);}
    100%{box-shadow: 0 0 0 0 rgba(0,135,226,0), 0 0 0 15px rgba(0,135,226,0);}
}

.halfTransparent{
  opacity: 0.5;
  filter: alpha(opacity=0.5);
}

@keyframes fadePulse{
    0%{opacity:0.3;}
    50%{opacity:0.7;}
    100%{opacity:0.3;}
}

.fadePulse{animation:fadePulse 2s infinite ease-in-out;}

@keyframes bumpGreenPulse{
    0%{transform:scale(1);}
    15%{transform:scale(2);}
    50%{transform:scale(1);box-shadow: 0 0 0 0 rgba(139,192,27,0.7);}
    100%{box-shadow: 0 0 0 40px rgba(139,192,27,0);}
}