/*
 * Sidebar
 */

.wrapper {
    align-items: stretch;
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	main {
	    padding-left: 240px;
	}
}

.sidebar {
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px;
    z-index: 600;

	@media (max-width: map-get($grid-breakpoints, "lg")) {
		top: 3em;
    	width: 100%;
	}

	.sidebar-heading {
		color: var(--bs-white);
		text-transform: uppercase;
		font-weight: 300;
		padding: 0 2em 0 0;
    	margin: 1em 0 0.5em 0;
	}
}

.bi {
	vertical-align: -.125em;
	pointer-events: none;
	fill: currentColor;
}

.dropdown-toggle {
	outline: 0;
}

.nav-flush .nav-link {
	border-radius: 0;
}

.btn-toggle {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	color: var(--bs-white);
	background-color: transparent;
	border: 0;
	min-width: 100%;
}

.btn-toggle:hover,
.btn-toggle:focus {
	color: var(--bs-black);
	font-weight: 600;
}

.btn-toggle::before {
	color: var(--black);
	width: 1.25em;
	line-height: 0;
	content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
	transition: transform .35s ease;
	transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
	color: var(--bs-black);
}

.btn-toggle[aria-expanded="true"]::before {
	transform: rotate(90deg);
	margin-top: 5px;
}

.btn-toggle-nav a {
	display: inline-flex;
	padding: .1875rem .5rem;
	margin-top: .125rem;
	margin-left: 1.25rem;
	text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
	background-color: var(--bs-light);
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: .5rem;
	overflow-x: hidden;
	overflow-y: auto;
	/* Scrollable contents if viewport is shorter than content. */
}