// Fonts
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:wght@100,200,300,400,500,600,700,800,900,1000&display=swap");

// Variables
@import "base/custom";

// Styles
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~jquery-ui/themes/base/all.css";

// Layout
@import "layout/themes/sidebar/top-css";
@import "layout/themes/sidebar/sidebar-css";
@import "layout/footer";

// Modules
@import "modules/alert";
@import "modules/card";
@import "modules/nav";
@import "modules/offer";
@import "modules/applications-table";
@import "modules/cookie-consent";
@import "modules/service_quick_create";
@import "modules/application";
@import "modules/thread";
@import "modules/placeholder";
@import "modules/toastui";

//Routes
@import "routes/application-users";
@import "routes/data-table";
@import "routes/organization-inventory";
@import "routes/welcome";

//Blueprint
@import "blueprint/blueprint-base";
@import "blueprint/blueprint-applications";
@import "blueprint/blueprint-arrows";
@import "blueprint/blueprint-areas";
@import "blueprint/blueprint-shapes";
@import "blueprint/blueprint-toolbars";