.mapArea{
    transition:opacity 0.5s;
    position:absolute;
    top:0;
    left:0;
    display:flex;
    align-items:start;
    justify-content:center;
    text-align:center;
    font-size:1em;
    background:rgba($mapcolorDefault,0.1);
    border:1px rgba($mapcolorDefault,0.5) solid;
    color:$mapcolorDefault;
    border-radius:5px;
    *{ /*Ignore mouse events on all children*/
        pointer-events:none;
    }
    &.editing{ /* Area only has this class when it's being created (drawn on the blueprint) */
        cursor:default;
        border-style:dashed;
        pointer-events:none !important;
    }
    .title{
        font-weight: 600;
        text-shadow: none;
        -webkit-font-smoothing: antialiased;
    }
    .description{
        font-size:0.8em;
        text-shadow: none;
        -webkit-font-smoothing: antialiased;
    }
    > div{
        text-shadow:rgba(0,0,0,0.1) 1px 1px;
        margin:0.1em 0.5em;
    }
}

.appContainerEditable{
    .mapArea.selected{
        box-shadow:0 0 5px rgba(0,0,0,0.6);
        will-change:transform;
    }
}

.as-{ /* For "Area Style" */
    &size-{
        &xs{font-size:0.8em;}
        &x1{font-size:1.0em;}
        &x2{font-size:1.2em;}
        &x3{font-size:1.4em;}
        &x4{font-size:1.6em;}
    }
    &fillcolor-{
        &default{background:rgba($mapcolorDefault,0.1) !important;}
        &color01{background:rgba($mapcolor01,0.1) !important;}
        &color02{background:rgba($mapcolor02,0.1) !important;}
        &color03{background:rgba($mapcolor03,0.1) !important;}
        &color04{background:rgba($mapcolor04,0.1) !important;}
        &color05{background:rgba($mapcolor05,0.1) !important;}
        &color06{background:rgba($mapcolor06,0.1) !important;}
        &color07{background:rgba($mapcolor07,0.1) !important;}
        &color08{background:rgba($mapcolor08,0.1) !important;}
        &color09{background:rgba($mapcolor09,0.1) !important;}
        &color10{background:rgba($mapcolor10,0.1) !important;}
        &color11{background:rgba($mapcolor11,0.1) !important;}
        &color12{background:rgba($mapcolor12,0.1) !important;}
        &color13{background:rgba($mapcolor13,0.1) !important;}
        &color14{background:rgba($mapcolor14,0.1) !important;}
        &color15{background:rgba($mapcolor15,0.1) !important;}
        &color16{background:rgba($mapcolor16,0.1) !important;}
        &color17{background:rgba($mapcolor17,0.1) !important;}
        &white{background:$mapcolorWhite !important;}
        &no{background:transparent !important;}
    }
    &bordercolor-{
        &default{border-color:rgba($mapcolorDefault,0.5);color:$mapcolorDefault;}
        &color01{border-color:rgba($mapcolor01,0.5);color:$mapcolor01;}
        &color02{border-color:rgba($mapcolor02,0.5);color:$mapcolor02;}
        &color03{border-color:rgba($mapcolor03,0.5);color:$mapcolor03;}
        &color04{border-color:rgba($mapcolor04,0.5);color:$mapcolor04;}
        &color05{border-color:rgba($mapcolor05,0.5);color:$mapcolor05;}
        &color06{border-color:rgba($mapcolor06,0.5);color:$mapcolor06;}
        &color07{border-color:rgba($mapcolor07,0.5);color:$mapcolor07;}
        &color08{border-color:rgba($mapcolor08,0.5);color:$mapcolor08;}
        &color09{border-color:rgba($mapcolor09,0.5);color:$mapcolor09;}
        &color10{border-color:rgba($mapcolor10,0.5);color:$mapcolor10;}
        &color11{border-color:rgba($mapcolor11,0.5);color:$mapcolor11;}
        &color12{border-color:rgba($mapcolor12,0.5);color:$mapcolor12;}
        &color13{border-color:rgba($mapcolor13,0.5);color:$mapcolor13;}
        &color14{border-color:rgba($mapcolor14,0.5);color:$mapcolor14;}
        &color15{border-color:rgba($mapcolor15,0.5);color:$mapcolor15;}
        &color16{border-color:rgba($mapcolor16,0.5);color:$mapcolor16;}
        &color17{border-color:rgba($mapcolor17,0.5);color:$mapcolor17;}
        &white{border-color:rgba($mapcolorWhite,0.5);}
        &no{border-color:transparent;background:transparent;}
    }
    &border-{
        &no{border-width:0px;}
        &1{border-width:1px;}
        &2{border-width:2px;}
        &3{border-width:3px;}
        &4{border-width:4px;}
    }
    &corners-{
        &default{border-radius:5px;}
        &sharp{border-radius:0px;}
        &round{
            border-radius:20px;
            &.as-position-topleft > div,
            &.as-position-topcenter > div,
            &.as-position-topright > div,
            &.as-position-middleleft > div,
            &.as-position-middlecenter > div,
            &.as-position-middleright > div,
            &.as-position-bottomleft > div,
            &.as-position-bottomcenter > div,
            &.as-position-bottomright > div
            {padding:5px;}
        }
    }
    &position-{
        &topleft{align-items:start;justify-content:left;text-align:left;}
        &topcenter{align-items:start;justify-content:center;text-align:center;}
        &topright{align-items:start;justify-content:flex-end;text-align:right;}

        &middleleft{align-items:center;justify-content:left;text-align:left;}
        &middlecenter{align-items:center;justify-content:center;text-align:center;}
        &middleright{align-items:center;justify-content:flex-end;text-align:right;}

        &bottomleft{align-items:flex-end;justify-content:left;text-align:left;}
        &bottomcenter{align-items:flex-end;justify-content:center;text-align:center;}
        &bottomright{align-items:flex-end;justify-content:flex-end;text-align:right;}

        &topcenterover{
            align-items:start;justify-content:center;text-align:center;
            > div{margin-top:-12px;}
            span{padding:0px 5px;background:rgba(255,255,255,0.8);box-shadow:0px 0px 7px 4px rgba(255,255,255,0.8);}
            &.as-corners-round > div{margin-top:-17px;}
            &.as-size-xs > div{margin-top:-10px;}
            &.as-size-x1 > div{margin-top:-12px;}
            &.as-size-x2 > div{margin-top:-14px;}
            &.as-size-x3 > div{margin-top:-16px;}
            &.as-size-x4 > div{margin-top:-19px;}
            &.as-fillcolor-no span{background:white !important;box-shadow:none !important;}
        }

        &bottomcenterover{
            align-items:flex-end;justify-content:center;text-align:center;
            > div{margin-bottom:-9px;}
            span{padding:0px 5px;background:rgba(255,255,255,0.8);box-shadow:0px 0px 7px 4px rgba(255,255,255,0.8);}
            &.as-corners-round > div{margin-bottom:-14px;}
            &.as-size-xs > div{margin-bottom:-7px;}
            &.as-size-x1 > div{margin-bottom:-10px;}
            &.as-size-x2 > div{margin-bottom:-12px;}
            &.as-size-x3 > div{margin-bottom:-14px;}
            &.as-size-x4 > div{margin-bottom:-15px;}
            &.as-fillcolor-no span{background:white !important;box-shadow:none !important;}
        }

        &topcenterabove{
            align-items:start;justify-content:center;text-align:center;
            > div > div{transform:translate(0,-100%);padding-bottom:5px;}
        }

        &bottomcenterbelow{
            align-items:flex-end;justify-content:center;text-align:center;
            > div > div{margin-bottom:-100%;padding-top:5px;}
        }
    }
}