@keyframes greenPulse{
	0%{opacity:0;box-shadow: 0 0 0 0 rgba(139,192,27,0.7);}
	30%{opacity:1;}
	100%{box-shadow: 0 0 0 40px rgba(139,192,27,0);}
}

.applicationNode{
	padding:5px 9px;
	z-index:3;
	position:absolute;
	background:var(--bs-white);
	color:var(--bs-black);
	border-radius:3px;
	border:1px solid $mapcolorDefault;
	opacity:1;
	transition:opacity .5s;
	display:flex;
	&::before{
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		box-shadow:
			0 2.8px 2.2px rgba(0, 0, 0, 0.034),
			0 6.7px 5.3px rgba(0, 0, 0, 0.048),
			0 12.5px 10px rgba(0, 0, 0, 0.06),
			0 22.3px 17.9px rgba(0, 0, 0, 0.072),
			0 41.8px 33.4px rgba(0, 0, 0, 0.086),
			0 100px 80px rgba(0, 0, 0, 0.12);
		transition:opacity 0.5s;
		opacity:0;
		pointer-events:none !important;
	}
	&.highLight::before{opacity:1;}
	&.newNode{animation:greenPulse 0.7s ease-out 1;}
	&.hover{cursor:pointer !important;}
	a:hover{text-decoration:underline !important;}
	img.icon{width:16px;height:16px;vertical-align:middle;margin-top:-1px;display:inline-block;}
	.logo{border-radius:5px;width:auto;height:auto;max-width:90px;max-height:40px;display:none;}
	.appname{display:inline-block;}
	h5{
		font-size:1em !important;
		font-weight:normal;
	}
	.letter-icon{
		position: relative;
		display:inline-block;
		width:16px;
		height:16px;
		vertical-align:middle;
		margin-top:-1px;
		text-transform:uppercase;
		text-decoration:none;
		border-radius:1px;
		&:before{
			content:attr(first-letter);
			font-weight:bold;
			@include center-middle();
		}
	}
	.applicationMenu{ /* The icons that appear on mouse over application */
		a{
			font-size: x-small;
			padding:0 3px;
			&.addConnection{
				position: absolute;
				&.addConnectionRight{right:-12px;top:50%;transform:translate(0,-50%);}
				&.addConnectionLeft{left:-12px;top:50%;transform:translate(0,-50%);}
			}
		}
	}
	&.dragging .applicationMenu{display:none;}
	.billingDetailsContainer{ /* Billing details for the app. It's hidden in default mode */
		display:none;
		position:absolute;
		bottom:10px;
		height:0;
		left:50%;
		.billingDetails{
			width:100%;
			margin-left:-50%;
			text-align:center;
			vertical-align: -webkit-baseline-middle;
			white-space:nowrap;
			.badge.costs{
				pointer-events:none;
			}
		}
	}
}

.appContainerEditable.showCosts .applicationNode .billingDetailsContainer{ /* Showing the costs */
	display:inline-block;
	z-index: 4;
}

.appContainerEditable{
	.applicationNode .appname,
	.applicationNode .appname *{ /* Disable mouse events for everything inside application appname */
		pointer-events:none !important;
	}
	.applicationNode.selected{ /* Selected application */
		box-shadow:0 0 5px rgba(0,0,0,0.6);
		will-change:transform;
	}
}

.modal-dialog .applicationNode{ /* When displaying an app in a modal */
	display:inline-block;
	position:relative;
	margin:5px;
	cursor:pointer;
	&:hover{
		box-shadow:
			0 2.8px 2.2px rgba(0, 0, 0, 0.034),
			0 6.7px 5.3px rgba(0, 0, 0, 0.048),
			0 12.5px 10px rgba(0, 0, 0, 0.06),
			0 22.3px 17.9px rgba(0, 0, 0, 0.072),
			0 41.8px 33.4px rgba(0, 0, 0, 0.086),
			0 100px 80px rgba(0, 0, 0, 0.12);
	}
	.billingDetailsContainer{display:none !important;}
}


.ns-{ /* For "Node Style". Here are all classes that are used to alter the appearance of the app on the blueprint */
	&mode-{
		&name{
			.logo{display:none !important;}
			.appname{display:inline-block !important;}
		}
		&logo{
			.logo{display:block !important;}
			.appname{display:none !important;}
		}
	}
	&size-{
		&xs{ /* ns-size-xs - this is the naming convention appMap.js class expects. ns-[property]-[value] */
			font-size:0.8em;padding:4px 6px;
			h5{font-size:0.8em !important;}
			img.logo{max-width:60px;max-height:30px;}
			&.ns-corners-round img.logo{margin:2px;}
			&.ns-mode-logo{padding:3px;}
		}
		&x1{  /* ns-size-x1 - js parses these classes and stores styles in JSON object of form {[property]:[value]} for every app */
			font-size:1.0em;padding:5px 9px;
			h5{font-size:1.0em !important;}
			img.logo{max-width:80px;max-height:40px;}
			&.ns-corners-round img.logo{margin:4px;}
			&.ns-mode-logo{padding:4px;}
		}
		&x2{
			font-size:1.2em;padding:5px 10px;
			h5{font-size:1.2em !important;}
			img.logo{max-width:100px;max-height:50px;}
			&.ns-corners-round img.logo{margin:6px;}
			&.ns-mode-logo{padding:5px;}
		} 
		&x3{
			font-size:1.4em;padding:5px 10px;
			h5{font-size:1.4em !important;}
			img.logo{max-width:110px;max-height:60px;}
			&.ns-corners-round img.logo{margin:8px;}
			&.ns-mode-logo{padding:6px;}
		} 
		&x4{
			font-size:1.6em;padding:5px 10px;
			h5{font-size:1.6em !important;}
			img.logo{max-width:120px;max-height:70px;}
			&.ns-corners-round img.logo{margin:10px;}
			&.ns-mode-logo{padding:7px;}
		}
	}
	&bold-{
		&off{}
		&on{font-weight:bold;h5{font-weight:bold;}}
	}
	&border-{
		&no{border-width:0px;}
		&1{border-width:1px;}
		&2{border-width:2px;}
		&3{border-width:3px;}
		&4{border-width:4px;}
	}
	&corners-{
		&default{border-radius:5px;&::before{border-radius:5px;}}
		&sharp{border-radius:0px;&::before{border-radius:0px;}}
		&round{border-radius:1000px;&::before{border-radius:1000px;}}
	}
	&bordercolor-{
		&no{border-color:transparent;background:transparent;}
		&default{border-color:$mapcolorDefault;}
		&color01{border-color:$mapcolor01;}
		&color02{border-color:$mapcolor02;}
		&color03{border-color:$mapcolor03;}
		&color04{border-color:$mapcolor04;}
		&color05{border-color:$mapcolor05;}
		&color06{border-color:$mapcolor06;}
		&color07{border-color:$mapcolor07;}
		&color08{border-color:$mapcolor08;}
		&color09{border-color:$mapcolor09;}
		&color10{border-color:$mapcolor10;}
		&color11{border-color:$mapcolor11;}
		&color12{border-color:$mapcolor12;}
		&color13{border-color:$mapcolor13;}
		&color14{border-color:$mapcolor14;}
		&color15{border-color:$mapcolor15;}
		&color16{border-color:$mapcolor16;}
		&color17{border-color:$mapcolor17;}
		&white{stroke:$mapcolorWhite;fill:$mapcolorWhite;}
	}
}

.ns-border-demo{
	display:inline-block;
	margin-bottom:0.2rem;
	border-bottom-style:solid;
	min-width:35px;
	width: 100%;
	&.ns-border-no,&.as-border-no{
		border-bottom-color:rgba(0,0,0,0.4);
		border-bottom-style:dotted;
		border-bottom-width:0.5px !important;
	}
}

.ns-corners-demo{
	display:inline-block;
	border-top:1px solid #252525;
	border-right:1px solid #252525;
	width:1.2em;
	height:1.2em;
	border-bottom-left-radius:0px;
	border-bottom-right-radius:0px;
	border-top-left-radius:0px;
	margin-bottom:-0.2em;
}