.cookie-consent {
	width: 100%;
  	position: fixed;
  	bottom: 0;
  	left:0;
	background: var(--bs-warning);
	color: var(--bs-white);
	text-align: center;
	padding: 7px 0;
	z-index: 999;
}