.main_footer {
    z-index:11;
	padding: 3rem 0;
	border-top: 1px var(--gray-200) solid;
	background-color: var(--dark);
	color: var(--white);
	margin-top:3rem;

	.footer__heading {
		color: var(--white) !important;
		font-size: 1.2rem;	
	}

	.footer__list-item {
		padding-bottom: .085rem;

		a {
			color: var(--white) !important;
		}
	}
}
