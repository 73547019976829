// set custom colors
$custom-colors: (
    "primary": #0078c9,
    "warning": #f99935,
    "danger": #a92525,
    "muted": #829AB1,
    "primary-dark": #1c57a4,
    "primary-light": #498ad4,
    "white-ghost": #f5f6fa,
    "black": $black,
    "secondary": #475259,
    "white": $white,
    "light":  #eff2f7,
    "dark": #061222
);

// merge maps
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$utilities-alert-colors: map-loop($utilities-colors, rgba-css-var, "$key", "alert");

// Typography
$font-family-sans-serif: "Noto Sans TC", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base: 1rem;
$lead-font-size: 1.5rem;
$line-height-base: 1.6;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;

$headings-font-family: 'Noto Sans TC', sans-serif;
$headings-font-weight: 800;
$headings-line-height: 1.2;
$headings-margin-bottom: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$line-height-base: 1.5;
$line-height-sm:1.25;
$line-height-lg: 2;

$nav-pills-link-active-color: $black;
$nav-pills-link-active-bg: $gray-200;

$enable-responsive-font-sizes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-negative-margins: true;
$enable-shadows: true;
$enable-rounded: true;
$enable-print-styles: true;
$enable-caret: true;
$popover-max-width: 350px;

$grid-columns:      12;
$grid-gutter-width: 30px;

