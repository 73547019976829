.card {
	margin-bottom: 2rem;
}

.favorite {
	background: #f2f8ff !important;
}


a.btn.addApp {
	z-index:1;
	border-bottom-left-radius: unset;
	border-bottom-right-radius: unset;
	border-top-left-radius: unset;
}

.card-add-button {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}


	