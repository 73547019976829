.offer {

	h4 {
		font-size: 1.8em;
		font-weight: 800;
		color: var(--bs-black);
	}

	&__description {
		font-size: 0.95em;
		font-weight: 400;
		color: var(--bs-secondary);
	}

	&__terms {
		font-family: arial, sans sherif;

		h6 {
			font-weight: 800;
		}
		p {
			font-size: 0.9em;
			font-weight: 400;
			color: $text-muted;
		}
	}

	&__type {
		font-size: 0.65em;
		font-family: arial, sans sherif;
		font-weight: 200;
		text-transform: uppercase;
	}

	&__discount {
		font-size: 1.5em;
		font-style: italic;
	}	

	&__code {
		font-size: 1.5em;
		font-style: italic;
		border: 5px solid var(--bs-success);
		border-style: dashed;
		background: var(--bs-white);
		padding: 5px;
		text-align: center;
	}

}